const UserDataKeyName = 'FATCOUPON-USER-DATA';

export const getUserData = () => {
  const data = localStorage.getItem(UserDataKeyName);

  if (!data) {
    return null;
  }

  return JSON.parse(data);
};

export const setUserData = (data: object) => {
  if (!data) {
    return;
  }

  localStorage.setItem(UserDataKeyName, JSON.stringify(data));
};
