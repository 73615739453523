export const VersionStatus = {
  LATEST: 10,

  ACTIVE: 0,

  DEPRECATED: -10,

  UNSUPPORTED: -20,

  UNDEFINED: -30,

  DELETED: -40,
};
