import { Method } from 'axios';

import { getUrl, requestWrapper } from '../util';

type URLParameterRequest = (urlParams: any, options?: any) => Promise<any>;
type CommonRequest = (options?: any) => Promise<any>;
type EndpointRequest = CommonRequest | URLParameterRequest;

export interface Endpoint {
  uri: string,
  method: Method,
  handler?: EndpointRequest,
}

interface FatCouponEndpoints {
  [property: string]: Endpoint
}

export const Endpoints: FatCouponEndpoints = {
  Login: {
    uri: '/auth/login/admin',
    method: 'post',
  },
  Logout: {
    uri: '/auth/logout',
    method: 'post',
  },
  UserData: {
    uri: '/user',
    method: 'get',
  },
  ListVersions: {
    uri: '/versions',
    method: 'get',
  },
  ListAndroidVersions: {
    uri: '/versions/android',
    method: 'get',
  },
  ListIOSVersions: {
    uri: '/versions/ios',
    method: 'get',
  },
  GetVersion: {
    uri: '/versions/:id',
    method: 'get',
  },
  CreateVersion: {
    uri: '/versions',
    method: 'post',
  },
  UpdateVersion: {
    uri: '/versions/:id',
    method: 'put',
  },
  DeleteVersion: {
    uri: '/versions/:id',
    method: 'delete',
  },
  GetAndroidLatestVersion: {
    uri: '/versions/latest/android',
    method: 'get',
  },
  GetIOSLatestVersion: {
    uri: '/versions/latest/ios',
    method: 'get',
  },
};

Object.values(Endpoints).forEach((endpoint: Endpoint) => {
  if (/:.+/.test(endpoint.uri)) {
    // eslint-disable-next-line no-param-reassign
    endpoint.handler = async (urlParams: any, options?: any) => {
      const url = getUrl(endpoint, urlParams);

      return requestWrapper(
        url,
        endpoint.method,
        options,
      );
    };
  } else {
    // eslint-disable-next-line no-param-reassign
    endpoint.handler = async (options?: any) => {
      const url = getUrl(endpoint);

      return requestWrapper(
        url,
        endpoint.method,
        options,
      );
    };
  }
});
