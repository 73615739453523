const TokenKeyName = 'FATCOUPON-DEVELOPER-TOKEN';
const ExpirationKeyName = 'FATCOUPON-DEVELOPER_TOKEN_EXPIRATION';

export const getToken = (): string | null => {
  const expiration: string | null = localStorage.getItem(ExpirationKeyName);

  if (!expiration || Date.now() / 1000 > Number(expiration)) {
    return null;
  }

  return localStorage.getItem(TokenKeyName);
};

export const setToken = (token: string, expiration: number): boolean => {
  if (!token || !expiration) {
    return false;
  }

  localStorage.setItem(TokenKeyName, token);
  localStorage.setItem(ExpirationKeyName, expiration.toString());

  return true;
};

export const deleteToken = (): void => {
  localStorage.removeItem(TokenKeyName);
  localStorage.removeItem(ExpirationKeyName);
};
