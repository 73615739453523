import {
  Layout, Form, Input, Button, notification,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { createHash } from 'crypto';
import React, { PureComponent } from 'react';

import { Endpoints, HttpStatus } from '../constant';
import { setToken, setUserData } from '../util';

import '../style/login.css';

export class LoginComponent extends PureComponent {
  private form: React.RefObject<FormInstance> = React.createRef<FormInstance>()

  constructor(props: any) {
    super(props);

    this.onReset = this.onReset.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }

  onFinish(value: any) {
    this.login(value);
  }

  onReset() {
    this.form.current?.resetFields();
  }

  // eslint-disable-next-line class-methods-use-this
  async login(value: any) {
    const { email, password } = value;
    const { handler } = Endpoints.Login;
    if (!handler) {
      throw new Error('系统错误');
    }

    const encrypted = createHash('md5').update(password).digest('hex');

    try {
      const resp = await handler({
        data: {
          email,
          password: encrypted,
        },
      });

      if (resp.status !== HttpStatus.OK) {
        throw new Error(resp.errmsg);
      }

      setToken(resp.data.token, resp.data.expiredAt);
      setUserData(resp.data.data);

      global.location.href = '/';
    } catch (err) {
      notification.error({
        message: err.message || '登陆失败',
      });
    }
  }

  render() {
    return (
      <Layout className="login-wrapper">
        <Layout className="login-form">
          <Form ref={this.form} onFinish={this.onFinish}>
            <Form.Item className="login-form-item">
              <div className="login-logo">
                FatCoupon APP版本管理系统
              </div>
            </Form.Item>
            <Form.Item
              label="邮箱"
              name="email"
              className="login-form-item"
              rules={[{ required: true, message: '请输入邮箱' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="密码"
              name="password"
              className="login-form-item"
              rules={[{ required: true, message: '请输入密码' }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item className="login-form-item">
              <Button htmlType="submit" className="login-button">
                登录
              </Button>
              <Button htmlType="button" className="login-button" onClick={this.onReset}>
                重置
              </Button>
            </Form.Item>
          </Form>
        </Layout>
      </Layout>
    );
  }
}
