import React, { PureComponent } from 'react';
import {
  Switch, Route, BrowserRouter, Redirect,
} from 'react-router-dom';

import {
  AddVersionComponent,
  AndroidListComponent,
  EditVersionComponent,
  IOSListComponent,
  IndexComponent,
  LoginComponent,
} from './';
import { getToken } from '../util';

export class RouterComponent extends PureComponent {
  render() {
    const isLogin = !!getToken();
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={LoginComponent} />
          {
            !isLogin && (<Redirect to="/login" />)
          }
          <Route path="/" exact component={IndexComponent} />
          <Route path="/android" component={AndroidListComponent} />
          <Route path="/ios" component={IOSListComponent} />
          <Route path="/add" component={AddVersionComponent} />
          <Route path="/edit" component={EditVersionComponent} />
        </Switch>
      </BrowserRouter>
    );
  }
}
