import React, { PureComponent } from 'react';

import { LayoutComponent, VersionListComponent } from '../component';

export class AndroidListComponent extends PureComponent {
  render() {
    return (
      <LayoutComponent title="Android版本管理">
        <VersionListComponent type="android" />
      </LayoutComponent>
    );
  }
}
