import {
  Table, Tag, notification, Popconfirm, Button, Layout,
} from 'antd';
import React, { PureComponent } from 'react';
import moment from 'moment';

import {
  AndroidFilled,
  AppleFilled,
  CheckCircleOutlined,
  CloseOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';

import { Endpoints, VersionStatus, HttpStatus } from '../constant';

import '../style/list.css';

type DeviceType = 'android' | 'ios';

interface VersionListProps {
  type: DeviceType,
}

interface VersionListState {
  type: DeviceType,
  data: any[],
  page: number,
  size: number,
  count: number,
  loading: boolean,
}

export class VersionListComponent extends PureComponent<VersionListProps, VersionListState> {
  constructor(props: VersionListProps) {
    super(props);

    this.state = {
      type: props.type,
      data: [],
      page: 1,
      size: 10,
      count: 0,
      loading: true,
    };
  }

  componentDidMount() {
    const { page, size } = this.state;
    this.fetchData(page, size);
  }

  get versionListColumns() {
    return [{
      title: '版本号',
      dataIndex: 'version',
      key: 'version',
      render: (text: string) => (<span>{text}</span>),
    }, {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
      render: (type: string) => {
        switch (type) {
          case 'android':
            return (<AndroidFilled />);
          case 'ios':
            return (<AppleFilled />);
          default:
            return (<CloseOutlined />);
        }
      },
    }, {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => {
        let status;
        switch (Number(text)) {
          case VersionStatus.LATEST:
            status = (
              <Tag icon={<CheckCircleOutlined />} color="gold">最新版本</Tag>
            );
            break;
          case VersionStatus.ACTIVE:
            status = (
              <Tag icon={<SyncOutlined />} color="success">活跃版本</Tag>
            );
            break;
          case VersionStatus.DEPRECATED:
            status = (
              <Tag icon={<ExclamationCircleOutlined />} color="warning">落后版本</Tag>
            );
            break;
          case VersionStatus.UNSUPPORTED:
            status = (
              <Tag icon={<CloseCircleOutlined />} color="error">不支持版本</Tag>
            );
            break;
          default:
            status = (
              <Tag icon={<MinusCircleOutlined />} color="default">未知版本</Tag>
            );
        }

        return status;
      },
    }, {
      title: '是否需要强制更新',
      dataIndex: 'isForce',
      key: 'isForce',
      render: (isForce: boolean) => (<span>{isForce ? '是' : '否'}</span>),
    }, {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string) => (<span>{moment(createdAt).add(8, 'h').format('YYYY年MM月DD日 HH:mm:ss')}</span>),
    }, {
      title: '操作',
      key: 'ops',
      render: (text: any, record: any) => (
        <div>
          <Button
            shape="circle-outline"
            className="list-actions"
            icon={<EditOutlined />}
            onClick={() => {
              location.href = `/edit?id=${record.id}`;
            }}
          />
          <Popconfirm
            placement="top"
            title="确定删除该版本？"
            okText="删除"
            cancelText="取消"
            onConfirm={() => this.deleteVersion(record.id)}
            okButtonProps={{
              id: record.id,
            }}
          >
            <Button shape="circle-outline" className="list-actions" icon={<DeleteOutlined />} />
          </Popconfirm>
        </div>
      ),
    }];
  }

  async fetchData(page: number, size: number) {
    await this.setState({
      loading: true,
    });

    const { type } = this.state;
    let request;

    switch (type) {
      case 'android':
        request = Endpoints.ListAndroidVersions.handler;
        break;
      case 'ios':
        request = Endpoints.ListIOSVersions.handler;
        break;
      default:
        return;
    }

    if (!request) {
      return;
    }

    const resp = await request({
      page,
      size,
    });

    if (resp.status !== HttpStatus.OK) {
      notification.error({
        message: resp.errmsg || '获取版本信息失败',
      });

      await this.setState({
        loading: false,
      });

      return;
    }

    await this.setState({
      data: resp.data.data,
      page: resp.data.pageNum,
      size: resp.data.pageSize,
      count: resp.data.count,
      loading: false,
    });
  }

  async pageChangeHandler(
    page: number,
  ): Promise<void> {
    const { size } = this.state;

    await this.setState({
      page,
    });

    await this.fetchData(page, size);
  }

  async pageSizeChangeHandler(
    current: number,
    value: number,
  ): Promise<void> {
    await this.setState({
      page: current,
      size: value,
    });

    await this.fetchData(current, value);
  }

  async deleteVersion(id: string) {
    if (!id) {
      return;
    }

    const { handler } = Endpoints.DeleteVersion;
    if (!handler) {
      notification.error({
        message: '系统错误',
      });
      return;
    }

    const resp = await handler({
      id,
    }, {});

    if (resp.status !== HttpStatus.OK) {
      notification.error({
        message: resp.errmsg || '删除失败',
      });
    } else {
      const { page, size } = this.state;
      this.fetchData(page, size);
    }
  }

  render() {
    const {
      data, loading, size, page, count,
    } = this.state;
    return (
      <Layout>
        <Table
          pagination={{
            position: ['bottomRight'],
            defaultPageSize: size,
            defaultCurrent: page,
            hideOnSinglePage: false,
            showSizeChanger: true,
            total: count,
            onChange: (newPage: number) => this.pageChangeHandler(newPage),
            onShowSizeChange: (
              current: number,
              pageSize: number,
            ) => this.pageSizeChangeHandler(current, pageSize),
          }}
          loading={loading}
          columns={this.versionListColumns}
          dataSource={data}
        />
      </Layout>
    );
  }
}
