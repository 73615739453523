import {
  Layout, Menu, Breadcrumb, Dropdown, Divider
} from 'antd';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import {
  HomeOutlined,
  DownOutlined,
  PoweroffOutlined,
  AndroidOutlined,
  AppleOutlined,
  EditOutlined,
} from '@ant-design/icons';

import { Endpoints } from '../constant';
import { getUserData, deleteToken } from '../util';

import '../style/main.css';

const { Content, Header, Sider } = Layout;

interface LayoutComponentProps {
  title: string,
}

interface LayoutComponentState {
  title: string,
}

export class LayoutComponent extends PureComponent<LayoutComponentProps, LayoutComponentState> {
  constructor(props: LayoutComponentProps) {
    super(props);

    this.state = {
      title: props.title,
    };

    this.onLogoutClick = this.onLogoutClick.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  onLogoutClick() {
    deleteToken();

    const { handler } = Endpoints.Logout;
    if (handler) {
      handler({});
    }

    global.location.href = '/login';
  }

  render() {
    const { children } = this.props;
    const { title } = this.state;
    const userData = getUserData();

    const menu = (
      <Menu>
        <Menu.Item onClick={this.onLogoutClick}>
          <div>
            <PoweroffOutlined />
            <span style={{marginLeft: 10}}>注销</span>
          </div>
        </Menu.Item>
      </Menu>
    );

    return (
      <Layout className="container-wrapper">
        <Header className="top-wrapper">
          <div className="top-logo"><span>FatCoupon APP版本管理系统</span></div>

          <div className="top-action">
            <Dropdown overlay={menu} trigger={['click']}>
              <span>
                你好
                {userData && `，${userData.firstName || ''} ${userData.lastName || ''}`}
                {' '}
                <DownOutlined />
              </span>
            </Dropdown>
          </div>
        </Header>
        <Layout>
          <Sider className="sider-wrapper">
            <Menu theme="dark">
              <Menu.Item key="index" active><HomeOutlined /><Link to="/">首页</Link></Menu.Item>
              <Menu.Item key="android"><AndroidOutlined /><Link to="/android">Android端版本管理</Link></Menu.Item>
              <Menu.Item key="ios"><AppleOutlined /><Link to="/ios">iOS端版本管理</Link></Menu.Item>
              <Menu.Item key="add"><EditOutlined /><Link to="/add">新建版本</Link></Menu.Item>
            </Menu>
          </Sider>
          <Content className="content-wrapper">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <HomeOutlined />
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {title}
              </Breadcrumb.Item>
            </Breadcrumb>
            <Divider />
            { children }
          </Content>
        </Layout>
      </Layout>
    );
  }
}
