import {
  Card, Tag, Layout, notification,
} from 'antd';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import {
  AndroidOutlined,
  AppleOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import { LayoutComponent } from '../component';
import { Endpoints, HttpStatus } from '../constant';

interface IndexComponentState {
  android: string;
  ios: string;
  loading: boolean;
}

export class IndexComponent extends PureComponent<any, IndexComponentState> {
  constructor(props: any) {
    super(props);

    this.state = {
      android: '',
      ios: '',
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchLatestVersions();
  }

  // eslint-disable-next-line class-methods-use-this
  async getLatestVersion(type: string) {
    const endpoint = Endpoints[`Get${type}LatestVersion`];
    if (!endpoint) {
      return '';
    }

    const { handler } = endpoint;
    if (!handler) {
      return '';
    }

    const resp = await handler({});

    if (resp.status !== HttpStatus.OK) {
      notification.error({
        message: resp.errmsg || `获取${type}版本信息失败`,
      });
      return null;
    }

    return resp.data?.data?.version;
  }

  async fetchLatestVersions() {
    this.setState({
      loading: true,
    });

    const android = await this.getLatestVersion('Android');
    const ios = await this.getLatestVersion('IOS');

    this.setState({
      android,
      ios,
      loading: false,
    });
  }

  render() {
    const { android, ios, loading } = this.state;
    if (loading) {
      return (
        <LayoutComponent title="首页">
          <Layout>
            <LoadingOutlined />
          </Layout>
        </LayoutComponent>
      );
    }

    return (
      <LayoutComponent title="首页">
        <Card
          title="Android最新版本"
          extra={(
            <div>
              <Link to="/android">更多</Link>
            </div>
        )}
        >
          {
            android
              ? (<Tag icon={<AndroidOutlined />} color="success">{android}</Tag>)
              : (<Tag icon={<CloseOutlined />} color="default">未知版本</Tag>)
          }
        </Card>
        <Card
          title="iOS最新版本"
          extra={(
            <div>
              <Link to="/ios">更多</Link>
            </div>
        )}
        >
          {
            ios
              ? (<Tag icon={<AppleOutlined />} color="success">{ios}</Tag>)
              : (<Tag icon={<CloseOutlined />} color="default">未知版本</Tag>)
          }
        </Card>
      </LayoutComponent>
    );
  }
}
