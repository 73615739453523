import axios, { Method } from 'axios';

import { getToken, deleteToken } from './token';
// eslint-disable-next-line no-unused-vars
import { AppConfig } from '../config';
import { Endpoint, HttpStatus } from '../constant';

export const requestWrapper = async (url: string, method: Method, options: any = {}) => {
  const token = getToken();

  try {
    const resp = await axios.request({
      url,
      method,
      headers: {
        ContentType: 'application/javascript',
        Authorization: token,
      },
      params: {
        ...(options.params || {}),
      },
      data: options.data || {},
    });

    return {
      ...resp.data,
      status: resp.status,
    };
  } catch (err) {
    if (err.response?.status === HttpStatus.UNAUTHORIZED) {
      deleteToken();
      global.location.href = '/';
    }

    return {
      ...(err.response?.data),
      status: err.response?.status,
    };
  }
};

export const getUrl = (endpoint: Endpoint, data?: any) => {
  let { uri } = endpoint;

  if (/:.+/.test(endpoint.uri)) {
    const properties = Object.keys(data);

    if (properties.length > 0) {
      properties.forEach((key: string) => {
        uri = uri.replace(`:${key}`, data[key]);
      });
    }
  }

  return `${AppConfig.server}${uri}`;
};
