export const HttpStatus = {
  OK: 200,

  CREATED: 201,

  BAD_REQUEST: 400,

  UNAUTHORIZED: 401,

  FORBIDDEN: 403,

  NOT_FOUND: 404,

  TOO_MANY_REQUEST: 429,

  SERVER_INTERNAL_ERROR: 500,
};
