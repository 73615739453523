import { Form, Select, Input, Checkbox, Button } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { PureComponent } from 'react';

import {
  AndroidFilled,
  AppleFilled,
} from '@ant-design/icons'

import { LayoutComponent } from './';
import { VersionStatus } from '../constant'

import '../style/editor.css';

export type DeviceType = 'android' | 'ios';

interface VersionEditorProps {
  title?: string;
  type?: DeviceType;
  version?: string;
  status?: number;
  isForce?: boolean;
  finishedHandler: (value: any) => Promise<void>;
}

interface VersionEditorState {
  title: string | undefined;
  type: DeviceType | undefined;
  version: string | undefined;
  status: number | undefined;
  isForce: boolean | undefined;
  finishedHandler: (value: any) => Promise<void>;
}

export class VersionEditorComponent extends PureComponent<VersionEditorProps, VersionEditorState> {
  private form: React.RefObject<FormInstance> = React.createRef<FormInstance>();

  constructor(props: VersionEditorProps) {
    super(props);

    const { title, type, version, status, isForce, finishedHandler } = this.props;

    this.state = {
      title,
      type,
      version,
      status,
      isForce,
      finishedHandler,
    };

    this.onFinished = this.onFinished.bind(this);
  }

  async onFinished(value: any) {
    const { finishedHandler } = this.state;

    await finishedHandler(value);
  }

  onReset() {
    this.form.current?.resetFields();
  }

  render() {
    const {
      title,
      type,
      version,
      status,
      isForce,
    } = this.state;

    return (
      <LayoutComponent title={title || `编辑${type || ''}版本`}>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          ref={this.form}
          onFinish={this.onFinished}
        >
          <Form.Item
            name="type"
            label="终端类型"
            rules={[
              { required: true, message: '请选择终端类型' },
            ]}
            initialValue={type}
          >
            <Select disabled={!!type}>
              <Select.Option value="android">
                <AndroidFilled />
                {' '}
                Android
              </Select.Option>
              <Select.Option value="ios">
                <AppleFilled />
                {' '}
                iOS
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="version"
            label="版本号"
            rules={[
              { required: true, message: '请填写版本号' },
              { pattern: /^v?\d+\.\d+.\d+$/, message: '请输入正确的版本号' },
            ]}
            initialValue={version}
          >
            <Input disabled={!!version} />
          </Form.Item>
          <Form.Item
            name="status"
            label="版本状态"
            initialValue={status || VersionStatus.LATEST}
          >
            <Select>
              <Select.Option value={VersionStatus.LATEST}>最新版本</Select.Option>
              <Select.Option value={VersionStatus.ACTIVE}>活跃版本</Select.Option>
              <Select.Option value={VersionStatus.DEPRECATED}>落后版本</Select.Option>
              <Select.Option value={VersionStatus.UNSUPPORTED}>不支持版本</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="isForce"
            label="是否强制更新"
          >
            <Checkbox defaultChecked={isForce !== undefined ? !!isForce : true} />
          </Form.Item>
          <Form.Item label="操作">
            <Button htmlType="submit" className="editor-button">
              提交
            </Button>
            <Button htmlType="button" className="editor-button" onClick={this.onFinished}>
              重置
            </Button>
          </Form.Item>
        </Form>
      </LayoutComponent>
    );
  }
}