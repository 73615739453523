
import { notification, Layout } from 'antd';
import React, { PureComponent } from 'react';

import { LoadingOutlined } from '@ant-design/icons';

import { DeviceType, VersionEditorComponent, LayoutComponent } from '../component';
import { Endpoints, HttpStatus } from '../constant'

interface EditVersionComponentState {
  isLoading: boolean;
  id?: string | null;
  type?: DeviceType;
  version?: string;
  status?: number;
  isForce?: boolean;
}

export class EditVersionComponent extends PureComponent<any, EditVersionComponentState> {
  constructor(props: any) {
    super(props);

    const id = new URLSearchParams(location.search).get('id');

    this.state = {
      isLoading: true,
      id,
    };

    this.updateVersion = this.updateVersion.bind(this);
  }

  async loadData(id: string) {
    let handler = Endpoints.GetVersion.handler;

    if (!handler) {
      notification.error({
        message: '系统错误',
      })
      return;
    }

    const resp = await handler({
      id,
    });

    if (resp.status !== HttpStatus.OK) {
      notification.error({
        message: resp.errmsg || '获取版本信息失败',
      });
      return;
    }

    await this.setState({
      isLoading: false,
      type: resp.data.data.type,
      version: resp.data.data.version,
      status: resp.data.data.status,
      isForce: resp.data.data.isForce,
    });
  }

  async componentDidMount() {
    const { id } = this.state;

    if (!id) {
      notification.error({
        message: '错误的版本信息'
      });
    } else {
      this.loadData(id);
    }
  }

  async updateVersion(value: any) {
    const { id } = this.state;

    try {
      const { handler } = Endpoints.UpdateVersion;
      if (!handler) {
        throw new Error('系统错误');
      }

      const resp = await handler({
        id,
      }, {
        data: value,
      });

      if (resp.status !== HttpStatus.OK) {
        throw new Error(resp.errmsg || '修改失败');
      }

      global.location.href = `/${value.type}`;
    } catch (err) {
      notification.error({
        message: err.message || '创建失败',
      });
    }
  }

  render() {
    const { type, version, status, isForce, isLoading } = this.state;

    if (isLoading) {
      return (
        <LayoutComponent title="编辑版本">
          <Layout>
            <LoadingOutlined />
          </Layout>
        </LayoutComponent>
      )
    } else {
      return (
        <VersionEditorComponent
          title='编辑版本'
          type={type}
          version={version}
          status={status}
          isForce={isForce}
          finishedHandler={this.updateVersion}
        />
      );
    }
  }
}