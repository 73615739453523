import React, { PureComponent } from 'react';

import { LayoutComponent, VersionListComponent } from '../component';

export class IOSListComponent extends PureComponent {
  render() {
    return (
      <LayoutComponent title="iOS版本管理">
        <VersionListComponent type="ios" />
      </LayoutComponent>
    );
  }
}
