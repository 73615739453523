import { notification } from 'antd';
import React, { PureComponent } from 'react';

import { DeviceType, VersionEditorComponent } from '../component';
import { Endpoints, HttpStatus } from '../constant';

interface AddVersionComponentProps {
  type?: DeviceType;
}

interface AddVersionComponentState {
  type?: DeviceType;
}

export class AddVersionComponent extends PureComponent<
  AddVersionComponentProps,
  AddVersionComponentState
> {
  constructor(props: AddVersionComponentProps) {
    super(props);

    this.state = {
      type: undefined,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  async createVersion(value: any) {
    try {
      const { handler } = Endpoints.CreateVersion;
      if (!handler) {
        throw new Error('系统错误');
      }

      const resp = await handler({
        data: value,
      });

      if (resp.status !== HttpStatus.CREATED) {
        throw new Error(resp.errmsg || '创建失败');
      }

      global.location.href = `/${value.type}`;
    } catch (err) {
      notification.error({
        message: err.message || '创建失败',
      });
    }
  }

  render() {
    const { type } = this.state;

    return (
      <VersionEditorComponent
        type={type}
        finishedHandler={this.createVersion}
      />
    );
  }
}
